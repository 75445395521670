export const SCREEN_SM = 640
export const SCREEN_MD = 768
export const SCREEN_LG = 1024
export const SCREEN_XL = 1280
export const SCREEN_2XL = 1536

export const TOP_BAR_HEIGHT = 52
export const SHOP_TOP_BAR_HEIGHT = 52

export const SLIDER_SWIPE_THRESHOLD = 50

export const PROTOCOL_DOMAIN = '.protocol.space'

export const EMAIL_TEMPLATE_PROTOCOL_LOGO =
  'https://i.ibb.co/TqkxyZkm/logo-email.png'
export const EMAIL_TEMPLATE_PROTOCOL_LOGO_DARK =
  'https://i.ibb.co/TqmCfCs8/dark-protocol-logo.png'
export const EMAIL_TEMPLATE_PROTOCOL_THUMBNAIL =
  'https://i.ibb.co/Q7Skt1rS/main-protocol-thumbnail.png'
export const EMAIL_TEMPLATE_ORDER_IMAGE_FALLBACK =
  'https://i.ibb.co/7xtVVdZF/no-image-found.png'
export const EMAIL_TEMPLATE_ORDER_PANEL_BACKGROUND =
  'https://i.ibb.co/39Y1n1tj/email-gradient-noise.png'
